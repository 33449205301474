<template>
  <div class="image">
    <div class="vnc-content" />
  </div>
</template>

<script>
import store from '@/store'
import Guacamole from 'guacamole-common-js'
export default {
  data() {
    return {
      uId: '',
      connection: '',
      token: '',
      canvasWidth: '1920',
      canvasHeight: '1040',
      client: '',
      keyboard: null
    }
  },
  mounted() {
    this.uId = store.getters.uId
    this.$nextTick(() => {
      this.connect()
    })
  },
  beforeUnmount() {
    if (this.client !== '') {
      this.client.disconnect()
    }
    this.removeKeyboardListeners()
  },
  methods: {
    connect() {
      const idJob = localStorage.idJob
      this.token = sessionStorage.desktopToken
      const connect = 'height=768&width=1024&id=' + idJob + '&token=' + this.token + '&regionId=' + window.localStorage.regionId
      // const tunnels = new Guacamole.WebSocketTunnel("ws://192.168.132.71:8811/webSocket",)
      // const socket = 'wss:' + location.host + '/vncSocket/ws/vnc/webSocket'
      var socket
      var ishttps = document.location.protocol == 'https:'
      if (ishttps) {
        socket = 'wss:' + location.host + '/vncSocket/ws/vnc/webSocket'
      } else {
        socket = 'ws:' + location.host + '/vncSocket/ws/vnc/webSocket'
      }
      const tunnels = new Guacamole.WebSocketTunnel(socket)
      var client = new Guacamole.Client(tunnels)
      this.client = client
      document.getElementsByClassName('vnc-content')[0].appendChild(client.getDisplay().getElement())
      document.getElementsByTagName('canvas').item(0).style = 'z-index: 1'
      document.getElementsByTagName('canvas').item(1).style = 'z-index: 1'
      document.getElementsByTagName('canvas').item(0).style.width = this.canvasWidth + 'px'
      document.getElementsByTagName('canvas').item(0).style.height = this.canvasHeight + 'px'
      client.connect(connect)
      var canvasElements = document.getElementsByTagName('canvas')
      for (var i = 0; i < canvasElements.length; i++) {
        const currentCanvas = canvasElements[i]
        currentCanvas.style = 'z-index: 1'
      }
      var mouse = new Guacamole.Mouse(client.getDisplay().getElement())
      mouse.onmousedown =
          mouse.onmouseup =
              mouse.onmousemove = function(mouseState) {
                client.sendMouseState(mouseState)
              }

      // Keyboard
      this.addKeyboardListeners(client)
    },
    addKeyboardListeners(client) {
      this.keyboard = new Guacamole.Keyboard(document)

      this.keyboard.onkeydown = function(keysym) {
        client.sendKeyEvent(1, keysym)
      }

      this.keyboard.onkeyup = function(keysym) {
        client.sendKeyEvent(0, keysym)
      }
    },
    removeKeyboardListeners() {
      if (this.keyboard) {
        this.keyboard.onkeydown = null
        this.keyboard.onkeyup = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
